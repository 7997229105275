import type { UseFetchOptions } from '#app'

interface ApiError {
  response: {
    _data: {
      error: string;
      message?: string;
    };
    status: number;
  };
}

export async function useApiFetch<T>(
  path: string | (() => string),
  options: Partial<UseFetchOptions<T>> = {}
) {
  const headers: HeadersInit = {}
  const config = useRuntimeConfig()

  const token = useCookie('XSRF-TOKEN')

  if (token.value) {
    headers['X-XSRF-TOKEN'] = token.value
  }

  if (import.meta.server) {
    const requestHeaders = useRequestHeaders(['referer', 'cookie'])
    Object.assign(headers, requestHeaders)
  }

  try {
    const response = await $fetch<T>(config.public.apiBase + path, {
      credentials: 'include',
      watch: false,
      ...options,
      headers: {
        ...headers,
        ...(options.headers as HeadersInit)
      }
    })

    return response
  } catch (error) {
    const err = error as ApiError
    
    if (err.response?._data) {
      const errorMessage = err.response._data.error || err.response._data.message
      console.error(`API Error (${err.response.status}):`, errorMessage)
      throw new Error(errorMessage ?? 'An unknown error occurred')
    }
    
    console.error('Network Error:', error)
    throw new Error('Unable to connect to the server.')
  }
}