import { default as _91slug_93xM5Wmiy3lEMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/blog/[slug].vue?macro=true";
import { default as indexjCcIOfj2XUMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/blog/index.vue?macro=true";
import { default as blogZM4KU2JYfQMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/blog.vue?macro=true";
import { default as checkoutSuccessiHMl2BU5FQMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/checkoutSuccess.vue?macro=true";
import { default as dashboard19o31s9crCMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/dashboard.vue?macro=true";
import { default as _91_46_46_46slug_93DLc4obnhfbMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/docs/[...slug].vue?macro=true";
import { default as _91sessionId_93LFcgXWev6FMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/[sessionId].vue?macro=true";
import { default as endEPVIC4tp3kMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/end.vue?macro=true";
import { default as _91_46_46_46slug_93Y6v7iQzqq6Meta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/live/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Z6Hn2XWV1QMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/wait/[...slug].vue?macro=true";
import { default as index79a2379M4yMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/index.vue?macro=true";
import { default as logind1GhTMPQ4YMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/login.vue?macro=true";
import { default as pricingcYaG3ubyFrMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/pricing.vue?macro=true";
import { default as signupHE0sgHqjWkMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/signup.vue?macro=true";
import { default as systemCheckvJM0zP8YvvMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/systemCheck.vue?macro=true";
import { default as termspggN5gi9CgMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/pages/terms.vue?macro=true";
import { default as component_45stubVD9LX3RBtVMeta } from "/codebuild/output/src3203529219/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVD9LX3RBtV } from "/codebuild/output/src3203529219/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: blogZM4KU2JYfQMeta?.name,
    path: "/blog",
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/blog.vue"),
    children: [
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "",
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/blog/index.vue")
  }
]
  },
  {
    name: "checkoutSuccess",
    path: "/checkoutSuccess",
    meta: checkoutSuccessiHMl2BU5FQMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/checkoutSuccess.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard19o31s9crCMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/dashboard.vue")
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/docs/[...slug].vue")
  },
  {
    name: "experience-sessionId",
    path: "/experience/:sessionId()",
    meta: _91sessionId_93LFcgXWev6FMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/[sessionId].vue")
  },
  {
    name: "experience-end",
    path: "/experience/end",
    meta: endEPVIC4tp3kMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/end.vue")
  },
  {
    name: "experience-live-slug",
    path: "/experience/live/:slug(.*)*",
    meta: _91_46_46_46slug_93Y6v7iQzqq6Meta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/live/[...slug].vue")
  },
  {
    name: "experience-wait-slug",
    path: "/experience/wait/:slug(.*)*",
    meta: _91_46_46_46slug_93Z6Hn2XWV1QMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/experience/wait/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index79a2379M4yMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logind1GhTMPQ4YMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingcYaG3ubyFrMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/pricing.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupHE0sgHqjWkMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/signup.vue")
  },
  {
    name: "systemCheck",
    path: "/systemCheck",
    meta: systemCheckvJM0zP8YvvMeta || {},
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/systemCheck.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/codebuild/output/src3203529219/src/synergos-frontend/pages/terms.vue")
  },
  {
    name: component_45stubVD9LX3RBtVMeta?.name,
    path: "/docs",
    component: component_45stubVD9LX3RBtV
  }
]