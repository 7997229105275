<script setup lang="ts">
import { ref } from 'vue'
import { Menu, X } from 'lucide-vue-next'

const links = [
  {
    label: 'Adventures',
    to: '/#adventures'
  },
  {
    label: 'Prices',
    to: '/pricing#pricing'
  }
]

const isMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<template>
  <header class="bg-white shadow-md py-4 px-6" @contextmenu.prevent>
    <div class="max-w-7xl mx-auto flex justify-between items-center">
      <div class="flex items-center">
        <NuxtLink to="/" class="text-2xl font-bold text-purple-900">SYNERGOS</NuxtLink>
      </div>

      <nav class="hidden md:flex space-x-8">
        <NuxtLink v-for="link in links" :key="link.to" :to="link.to"
          class="text-lg text-purple-700 hover:text-purple-900 transition-colors duration-200">
          {{ link.label }}
        </NuxtLink>
      </nav>

      <div class="hidden md:flex items-center space-x-4">
        <NuxtLink to="/login" class="text-purple-700 hover:text-purple-900 transition-colors duration-200">
          Sign in
        </NuxtLink>
        <NuxtLink to="/signup"
          class="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full transition-colors duration-200">
          Sign up
        </NuxtLink>
      </div>

      <button @click="toggleMenu" class="md:hidden text-purple-900">
        <Menu v-if="!isMenuOpen" class="h-6 w-6" />
        <X v-else class="h-6 w-6" />
      </button>
    </div>

    <!-- Mobile menu -->
    <div v-if="isMenuOpen" class="md:hidden mt-4">
      <nav class="flex flex-col space-y-4">
        <NuxtLink v-for="link in links" :key="link.to" :to="link.to"
          class="text-sm text-purple-700 hover:text-purple-900 transition-colors duration-200">
          {{ link.label }}
        </NuxtLink>
        <NuxtLink to="/login" class="text-sm text-purple-700 hover:text-purple-900 transition-colors duration-200">
          Sign in
        </NuxtLink>
        <NuxtLink to="/signup" class="text-sm text-purple-700 hover:text-purple-900 transition-colors duration-200">
          Sign up
        </NuxtLink>
      </nav>
    </div>
  </header>
</template>

<style scoped>
/* This style is in use and should be kept */
.router-link-active {
  @apply text-purple-900 font-semibold;
}

/* No other styles to remove */
</style>