/**
 * Adds resource hints (preconnect and dns-prefetch) to optimize resource loading
 * 
 * @param url - The URL to preconnect and prefetch DNS for
 * 
 * Resource hints help improve performance by:
 * - preconnect: Establishes early connections to important third-party origins
 * - dns-prefetch: Resolves DNS for specified domains ahead of time
 */
export default defineNuxtPlugin(() => {
  const addResourceHint = (url: string) => {
    // Only run on client-side since document is not available on server
    if (process.client) {
      // Add preconnect
      const preconnectLink = document.createElement('link');
      preconnectLink.rel = 'preconnect';
      preconnectLink.href = url;
      preconnectLink.crossOrigin = 'anonymous';
      document.head.appendChild(preconnectLink);
      
      // Add dns-prefetch
      const dnsPrefetchLink = document.createElement('link');
      dnsPrefetchLink.rel = 'dns-prefetch';
      dnsPrefetchLink.href = url;
      document.head.appendChild(dnsPrefetchLink);
    }
  };

  // Add resource hints for Google Tag Manager
  addResourceHint('https://www.googletagmanager.com');

  return {
    provide: {
      addResourceHint
    }
  };
});
